import Cookies from 'js-cookie'

const TokenKey = 'token'
const HasTokenKey = 'hasToken'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function getHasToken() {
  return Cookies.get(HasTokenKey)
}
//没设置时间就是基于会话的
// export function setToken(token) {
//   return Cookies.set(TokenKey, Date.now())
// }

export function removeToken() {
  Cookies.remove(HasTokenKey)
  console.log('清除token')
  return Cookies.remove(TokenKey)
}

export function setUserInfo(uid, account, wid) {
  localStorage.setItem('account', account)
  localStorage.setItem('uid', uid)
  localStorage.setItem('wid', wid)
}

export function getUserInfo() {
  const account = localStorage.getItem('account')
  const uid = localStorage.getItem('uid')
  const wid = localStorage.getItem('wid')
  return { 'account': account, 'uid': uid, 'wid': wid }
}

export function clearUserInfo() {
  localStorage.removeItem('account');
  localStorage.removeItem('uid');
  localStorage.removeItem('wid');
}
