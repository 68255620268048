import { createApp } from 'vue'
// pinia代替vuex
import { createPinia } from 'pinia';

import App from './App.vue'
import router from './router'
import '@/permission' // permission control

// 1. 引入你需要的组件
import {
    Button, Tabbar, Space, TabbarItem, NavBar, Swipe, SwipeItem,
    Tag, Overlay, Grid, GridItem, List, Cell, Dialog, Form, Field,
    CellGroup, ActionSheet, Icon, Tab, Tabs, DropdownMenu, DropdownItem,
    Popup, Uploader, Stepper, RadioGroup, Radio, NoticeBar
} from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';

const app = createApp(App)
const pinia = createPinia();
app.use(router);
app.use(NoticeBar);
app.use(Tag);
app.use(Popup);
app.use(Stepper);
app.use(Uploader);
app.use(pinia);
app.use(Space);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(RadioGroup);
app.use(Radio);
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(Button);
app.use(Overlay);
app.use(Tabbar);
app.use(TabbarItem);
app.use(NavBar);
app.use(Swipe);
app.use(SwipeItem);
app.use(Grid);
app.use(List);
app.use(Cell);
app.use(GridItem);
app.use(Dialog);
app.use(ActionSheet);
app.use(Icon);
app.use(Tab);
app.use(Tabs);
app.mount('#app');
