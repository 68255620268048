import { createRouter, createWebHashHistory } from 'vue-router';
// import HomePage from '@/views/HomePage.vue';
// import QuotesPage from '@/views/QuotesPage.vue';
// import RegisterPage from '@/views/RegisterPage.vue';
// import LoginPage from '@/views/LoginPage.vue';
// import MyPage from '@/views/MyPage.vue';
// import AssetsPage from '@/views/AssetsPage.vue';
// import CoinChartPage from '@/views/CoinChartPage.vue';
// import ChangePassword from '@/views/ChangePassword.vue';
// import ChangePayPassword from '@/views/ChangePayPassword.vue';
// import ChatPage from '@/views/ChatPage.vue';
// import TradeRecordPage from '@/views/TradeRecordPage.vue';
// import TradeEntrustRecordPage from '@/views/TradeEntrustRecordPage.vue';
// import CangPage from '@/views/CangPage.vue';
// import DepositWithdrawalRecordsPage from '@/views/DepositWithdrawalRecordsPage.vue';
// import DepositPage from '@/views/DepositPage.vue';
// import WithdrawalPage from '@/views/WithdrawalPage.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/HomePage.vue'), // 懒加载,
    meta: {
      title: "首页"
    }
  },
  {
    path: '/quotesPage',
    name: 'QuotesPage',
    component: () => import('@/views/QuotesPage.vue'), // 懒加载,
    meta: {
      title: "产品列表"
    }
  },
  {
    path: '/loginPage',
    name: 'LoginPage',
    component: () => import('@/views/LoginPage.vue'), // 懒加载,
  },
  {
    path: '/registerPage',
    name: 'RegisterPage',
    component: () => import('@/views/RegisterPage.vue'), // 懒加载,
    meta: {
      title: "注册"
    }
  },
  {
    path: '/myPage',
    name: 'MyPage',
    component: () => import('@/views/MyPage.vue'),
    meta: {
      title: "我的"
    }
  }, {
    path: '/changePassword',
    name: 'ChangePassword',
    component: () => import('@/views/ChangePassword.vue'),
    meta: {
      title: "修改密码"
    }
  }, {
    path: '/changePayPassword',
    name: 'ChangePayPassword',
    component: () => import('@/views/ChangePayPassword.vue'),
    meta: {
      title: "修改资金密码"
    }
  },
  {
    path: '/assetsPage',
    name: 'AssetsPage',
    component: () => import('@/views/AssetsPage.vue'),
    meta: {
      title: "总资产"
    }
  },
  {
    path: '/coinChartPage',
    name: 'CoinChartPage',
    component: () => import('@/views/CoinChartPage.vue'),
    meta: {
      title: "交易"
    }
  },
  {
    path: '/chatPage',
    name: 'ChatPage',
    component: () => import('@/views/ChatPage.vue'),
    meta: {
      title: "客服"
    }
  },
  {
    path: '/tradeRecordPage',
    name: 'TradeRecordPage',
    component: () => import('@/views/TradeRecordPage.vue'),
    meta: {
      title: "交易历史"
    }
  },
  {
    path: '/tradeEntrustRecordPage',
    name: 'TradeEntrustRecordPage',
    component: () => import('@/views/TradeEntrustRecordPage.vue'),
    meta: {
      title: "委买历史"
    }
  },
  {
    path: '/cangPage',
    name: 'CangPage',
    component: () => import('@/views/CangPage.vue'),
    meta: {
      title: "持仓"
    }
  },
  {
    path: '/depositWithdrawalRecordsPage',
    name: 'DepositWithdrawalRecordsPage',
    component: () => import('@/views/DepositWithdrawalRecordsPage.vue'),
    meta: {
      title: "充提记录"
    }
  },
  {
    path: '/depositPage',
    name: 'DepositPage',
    component: () => import('@/views/DepositPage.vue'),
    meta: {
      title: "入金"
    }
  },
  {
    path: '/withdrawalPage',
    name: 'WithdrawalPage',
    component: () => import('@/views/WithdrawalPage.vue'),
    meta: {
      title: "出金"
    }
  },
];

const router = createRouter({
  // 这个是不带#的需要后端处理找不到的页面
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;